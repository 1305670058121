/* You can add global styles to this file, and also import other style files */

// @import "~bootstrap/dist/css/bootstrap.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";

// .fc-day-sat{
//   background-color: #99999a !important;
// }
// .fc-day-sun{ 
//   background-color: #99999a !important;
// } 
.bgcolor {
  // background-color: #f4f5f7 !important;
  background-color: #fff !important;
}

.mat-form-field-wrapper {
  // padding-bottom: 0.34375em !important; 
}
mat-form-field {
   padding-top: 0 !important; 
   padding-bottom: 0 !important; 
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #0d8e1d !important;
  color: #fff;
}

.btop-primary {
  border-top: 3px solid #000000;
}

// .btn-primary {
//   color: #fff;
//   background-color: #99999a !important;
//   border-color: #99999a !important;
//   border-radius: 15px;
// }
.btn-outline-primary,
.btn-outline-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  color: #fff;
  background-color: #99999a !important;
  border-color: #99999a !important;
  border-radius: 15px;
}

.btop-success {
  border-top: 3px solid #28a9b0;
}

.btop-warning {
  border-top: 3px solid #dca235;
}

.btop-danger {
  border-top: 3px solid #dc3545;
}

.btop-default {
  border-top: 3px solid white;
}

// :host {
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//   font-size: 14px;
//   color: #333;
//   box-sizing: border-box;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
html,
body {
  height: 100%;
}

// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.card {
  display: -webkit-box !important;
}

// .h1,h2,h3,h4,h5,h6{
// margin-bottom: 1px !important;
// }
.main_content_iner {
  min-height: 68vh;
  transition: .5s;
  position: relative;
  background: #F6F7FB;
  margin: 0;
  z-index: 22;
  border-radius: 0px;
}

.example-full-width {
  width: 100%;
}

.pagination>li>a {
  background-color: white;
  color: #99999a;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination>.active>a {
  color: white;
  background-color: #99999a !Important;
  border: solid 1px #99999a !Important;
}

.pagination>.active>a:hover {
  background-color: #99999a !Important;
  border: solid 1px #99999a;
}

.cdk-overlay-container {
  z-index: 500 !important;
}

input {
  text-transform: capitalize;
} 

form {
  text-transform: capitalize;
}

form {
  text-transform: capitalize;
}

.container-fluid {
  text-transform: capitalize;
}

textarea {
  text-transform: capitalize;
}

////////////New CSS ///////
.icon-box {
  // height: 36px;
  // width: 36px;
  text-decoration: none;
  display: flex;
  position: relative;
  text-align: center;
  padding: 6px;
  box-sizing: border-box;

}

.icon-box span {
  position: relative;
  z-index: 99;
  margin: 0 auto;
  color: #fff;
  font-weight: 300;
}

.icon-box .icon-bg {
  position: absolute;
  left: 0;
  top: 0;
}

.dashboard-boxes ul li .icon-card {
  // position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  line-height: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}



.icon-card .icon-box {
  // 	height: 76px;
  // width: 76px;
  text-decoration: none;
  display: flex;
  position: relative;
  text-align: center;
  padding: 24px;
  box-sizing: border-box;

}

.icon-card .icon-box .icon-img {
  position: relative;
  z-index: 99;
  line-height: 100px;
  margin: 0 auto;
}

.icon-bg {
  padding: 10px;
}

.icon-card .icon-box .icon-bg {
  position: absolute;

  left: 0;
  top: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .75rem;
  font-weight: normal;
  line-height: 1.1;
  font-family: 'sf_bold';
}
/*==========================================================
							Fonts 
==========================================================*/

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 14px;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'sf_bold';
  src: url('./fonts/sfprodisplaybold-webfont.woff2') format('woff2'),
    url('./fonts/sfprodisplaybold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'sf_med';
  src: url('./fonts/sfprodisplaymedium-webfont.woff2') format('woff2'),
    url('./fonts/sfprodisplaymedium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'sf_reg';
  src: url('./fonts/sfprodisplayregular-webfont.woff2') format('woff2'),
    url('./fonts/sfprodisplayregular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


/*==========================================================
							General 
==========================================================*/


* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

}

body {
  margin: 0;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.3;
  color: #141414;
  background-color: #F5F5F7;
  font-family: 'sf_reg';

}

.clearfix:after,
.clearfix:before,
.col,
article,
aside,
figure,
footer,
header,
hgroup,
hr,
nav,
section {
  display: block
}

html ol,
html ul,
html ol li,
html ul li {
  list-style-type: none;
  padding: 0;
  margin: 0;
  list-style: none;
}

a,
button,
input,
select,
textarea {
  margin: 0
}

a {
  color: #141414;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  cursor: pointer;
}

a,
li {
  transition: 0.5s ease all;
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
}

a:hover,
li:hover {
  text-decoration: none !important;
  transition: 0.5s ease all;
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
}

a:focus,
button:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .75rem;
  font-weight: normal;
  line-height: 1.1;
  font-family: 'sf_bold';
}

h1 {
  font-size: 1.75rem;
}

h2 {
  font-size: 1.375rem;

}

h3 {
  font-size: 1.25rem;

}

h4 {
  font-size: 1.15rem;

}

h5 {
  font-size: 1rem;

}

h6 {
  font-size: .875rem;

}

img {
  max-width: 100%;
}


.bg-grey {
  background-color: #EEEEF3;
}

.brdr-primary {
  border: solid 1px #E7E7EB;
}

.primary-color {
  color: #141414;
}

.secondary-color {
  color: #ABABAB;
}

.highlighted-color {
  color: #0066CC;
}

/*==========================================================
				Start Leftbar here
==========================================================*/

.wrapper .leftside-menu {
  position: fixed;
  padding-top: 0;
  width: 70px;
  z-index: 5;
  padding-top: 120px;
  background: #fff;
  border-right: 1px solid #F5F5F7;
}

.side-nav .side-nav-item {
  margin: 10px 0;
}

.side-nav .side-nav-item .side-nav-link {
  padding: 20px;
  min-height: 54px;
  -webkit-transition: none;
  transition: none;
  font-size: 20px;
  display: block;
  text-align: center;
  color: #CFCFD8;
}

.side-nav .side-nav-item .side-nav-link:hover {
  background-color: #F5F5F7;
  color: #141414;
}

.side-nav .menuitem-active>a {
  background-color: #F5F5F7 !important;
  color: #141414 !important;
}


/*==========================================================
   Start Page Content here 
==========================================================*/

// .icon-box{
// height: 36px;
// width: 36px;
// text-decoration: none;
// display: flex;
// position: relative;
// text-align: center;
// padding:6px;
// box-sizing:border-box;

// }

//  .icon-box span{
// position: relative;
// z-index: 99;
// margin: 0 auto;
// color:#fff;
// font-weight: 300;
// }

// .icon-box .icon-bg{
// 	position:absolute;
// 	left:0; top:0;
// }

.wrapper .content-page {
  margin-left: 70px;
}

.content-page {
  margin-left: 260px;
  overflow: hidden;
  padding: 70px 12px 65px;
  min-height: 100vh;
}


.navbar-custom {
  padding: 0 20px;
  min-height: 60px;
  // position: fixed;
  // left: 80px;
  // top: 0;
  // right: 0;
  // z-index: 1001;
  // background-color:#fff;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.01) !important;
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.01) !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.01) !important;
  display: flex;
  justify-content: space-between;
}

.navbar-custom .btn {
  padding-top: .85rem;
  padding-bottom: .85rem;
}

.navbar-custom .clinic-logo {
  display: inline-block;
  line-height: 70px;
}

.dropdown-menu {
  border-color: #E7E7EB;
}

.navbar-custom .clinic-logo span {
  display: block;
  line-height: 70px;
}

.app-search form {
  padding: calc(32px * .5) 5px calc(32px * .5) 0;
  overflow: hidden;
  max-width: 320px;
  width: 300px;
}

.app-search form select {
  background-color: #F5F5F7;
  border-radius: 20px;
  border: 0;
  font-size: 14px;
  padding: 0.65rem 2.5rem 0.65rem 1.25rem;
  -moz-padding-start: calc(1.25rem - 3px);

}

.app-search form select option {
  font-family: 'sf_reg';
}

.navbar-custom .top-right {
  display: flex;
  align-items: center;
}

.dropdown-toggle::after {
  display: none;
}

.navbar-custom .btn {
  border: 0;
  padding-right: 0;
}

th {
  font-family: 'sf_bold';
}

th,
td {
  padding: 10px 15px;
}

.table-bordered {
  border: solid 1px #E7E7EB;
}


/* Dashboard Icons */

.dashboard-boxes h4 {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0;
}

.dashboard-boxes ul {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -10px;
}

.dashboard-boxes ul li {
  position: relative;
  width: calc(11% - 20px);
  box-sizing: border-box;
  margin: 10px;

}

.dashboard-boxes ul li a {
  background: #FFFFFF;
  border-radius: 1.75rem;
  // padding:20px 15px; text-align:center;
  -webkit-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.015) !important;
  -moz-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.015) !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.015) !important;
}


.dashboard-boxes ul li:before {
  content: "";
  display: block;
  padding-top: 90%;
  /* initial ratio of 1:1*/
}

.dashboard-boxes ul li .icon-card {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  line-height: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}



// .icon-card .icon-box{
// 	height: 76px;
// width: 76px;
// text-decoration: none;
// display: flex;
// position: relative;
// text-align: center;
// padding:16px;
// box-sizing:border-box;

// }
// .icon-card .icon-box .icon-img{
// 	position: relative;
// z-index: 99;
// line-height: 100px;
// margin: 0 auto;
// }

// .icon-card .icon-box .icon-bg{
// 	position:absolute;
// 	left:0; top:0;
// }	

.card {
  border-radius: 1.5rem;
  border: 0;
  // padding:30px;
  box-shadow: rgba(154, 161, 171, 0.04) 0px 12px 15px 0px;
}

/* Todays Appointment */
.appointment-card table th {
  background-color: #EEEEF3;
  padding: 15px 15px 15px 30px;
}

.appointment-card table th:first-child {
  border-radius: 1.4rem 0 0 0;
}

.appointment-card table th:last-child {
  border-radius: 0 1.4rem 0 0;
}

.appointment-card table td {
  padding: 5px 15px 5px 30px;
  font-size: 15px;
}

.appointment-card table .patient-name {
  font-size: 20px;
}

.appointment-card table .date {
  font-size: 15px;
  font-weight: 500;
}

.appointment-card table tbody {
  padding: 10px 0;
}

.appointment-card table tbody tr td:first-child {
  font-weight: 500;
}

.appointment-card table tbody tr:first-child td {
  padding-top: 20px;
}

.appointment-card table tbody tr:last-child td {
  padding-bottom: 30px;
}


.icon-btns .icon-box {
  height: 36px;
  width: 36px;
  text-decoration: none;
  display: flex;
  position: relative;
  text-align: center;
  padding: 6px;
  box-sizing: border-box;

}

.icon-btns .icon-box span {
  position: relative;
  z-index: 99;
  margin: 0 auto;
  color: #fff;
  font-weight: 300;
}

.icon-btns .icon-box .icon-bg {
  position: absolute;
  left: 0;
  top: 0;
}

.icon-btns ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
}

.icon-btns ul li {
  margin-right: 10px;
}


/* Appointment Detail */
.appointment-detail-card .detail {
  display: flex;
  flex-wrap: wrap;
}

.appointment-detail-card .detail .card-block span {
  display: block;
  font-size: 16px;
}

.appointment-detail-card .detail .card-block span.title {
  font-size: 14px;
}

.appointment-detail-card .detail .card-block {
  padding-right: 25px;
  position: relative;
}

.appointment-detail-card .detail .card-block:after {
  position: absolute;
  right: 10px;
  top: 0;
  height: 100%;
  width: 1px;
  background: #E7E7EB;
  content: "";
}

.appointment-detail-card .detail .card-block:last-child {
  padding: 0;
}

.appointment-detail-card .detail .card-block:last-child:after {
  display: none;
}

// .form-control{border: 1px solid #E7E7EB; padding: 0.5rem 0.75rem; color:#ABABAB; font-size:14px; border-radius: 0.275rem;}
.form-check-input {
  border: 1px solid #E7E7EB;
  width: 1.25em;
  height: 1.25em;
}

.form-check-input[type=checkbox] {
  border-radius: 0.15em;
}

label {
  display: inline-block;
  margin-bottom: 5px;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #ABABAB !important;
  font-size: 14px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ABABAB !important;
  font-size: 14px;
}

::placeholder {
  color: #ABABAB !important;
  font-size: 14px;
}

//  table th, table td{padding-right:50px;}
//  table th, table td{padding-left:25px;}
// table th{background:#EEEEF3; font-size:16px; padding-top:12px; padding-bottom:12px;}

.btn-primary {
  background-color: #141414;
  border-color: #141414;
}

.btn {
  font-family: 'sf_bold';
  font-size: 14px;
  border-radius: 4px;
}

.btn:hover {
  background-color: #0066CC;
  border-color: #0066CC;
}

.add-icon {
  font-size: 18px;
}

.select-service table th,
.select-service table td {
  padding-right: 50px;
}

.select-service table th,
.select-service table td {
  padding-left: 25px;
}

.select-service table th {
  background: #EEEEF3;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.wid-40 {
  width: 40%;
}

// .select-service .form-control{padding: 0.35rem 0.65rem;}
.nav-pills .nav-link {
  padding: 1rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  // background-color: #fff;
  // border-radius:0;
}

.nav-pills {
  border-radius: 1.2rem 1.2rem 0 0;
}

.timeline {
  padding: 15px 0px 15px 20px;
  border-left: 1px dashed #E7E7EB;
}

.timeline li {
  position: relative;
}

.timeline li:after {
  position: absolute;
  content: "";
  left: -25px;
  top: 3px;
  height: 10px;
  width: 10px;
  background: #0066CC;
  border-radius: 50%;
}

.timeline li h5 {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.timeline .title-text {
  font-family: 'sf_bold';
  min-width: 150px;
  padding-right: 15px;
}
.timeline2 {
  // padding: 15px 0px 15px 20px;
  border-left: 1px dashed #E7E7EB;
}

.timeline2 li {
  position: relative;
  float: left;
  min-width: 20rem;
  max-width: 20rem;
}

.timeline2 li:after {
  position: absolute;
  content: "";
  // left: -25px;
  top: 3px;
  height: 10px;
  width: 10px;
  background: #0066CC;
  border-radius: 50%;
}

.timeline2 li h5 {
  font-size: 1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
}

.timeline2 .title-text {
  font-family: 'sf_bold';
  min-width: 150px;
  padding-right: 15px;
}



/*=================================================
Media Quary
=================================================*/
@media (max-width:1599px) {
  .dashboard-boxes ul li {
    width: calc(20% - 20px);
  }

}

@media (max-width:1399px) {
  .dashboard-boxes ul li {
    width: calc(25% - 20px);
  }
}

@media (max-width:1199px) {
  .dashboard-boxes ul li {
    width: calc(33.333% - 20px);
  }
}

@media (max-width:992px) {
  .dashboard-boxes ul li {
    width: calc(50% - 20px);
  }

  .app-search form {
    width: auto;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }
}

@media (max-width:767px) {
  .dashboard-boxes ul li {
    width: calc(33.333% - 20px);
  }

  // .content-page{padding: 70px 0px 65px}
  .calendar-wraper {
    margin-top: 30px;
  }

  // .navbar-custom{padding: 0 10px;}

}

@media (max-width:639px) {
  .dashboard-boxes ul li {
    width: calc(50% - 20px);
  }

}

.icon-box2 {
  height: 36px;
  width: 36px;
  text-decoration: none;
  display: flex;
  position: relative;
  text-align: center;
  padding: 6px;
  box-sizing: border-box;

}

.icon-box2 span {
  position: relative;
  z-index: 99;
  margin: 0 auto;
  color: #fff;
  font-weight: 300;
}

.icon-box2 .icon-bg2 {
  position: absolute;
  left: 0;
  top: 0;
}

.icon-card .icon-box2 {
  height: 76px;
  width: 76px;
  text-decoration: none;
  display: flex;
  position: relative;
  text-align: center;
  padding: 16px;
  box-sizing: border-box;

}

.icon-card .icon-box2 .icon-img {
  position: relative;
  z-index: 99;
  line-height: 100px;
  margin: 0 auto;
}

.icon-card .icon-box2 .icon-bg2 {
  position: absolute;
  left: 0;
  top: 0;
}


.icon-btns .icon-box2 {
  height: 40px;
  width: 40px;
  text-decoration: none;
  display: flex;
  position: relative;
  text-align: center;
  padding: 6px;
  box-sizing: border-box;

}

.icon-btns .icon-box2 span {
  position: relative;
  z-index: 99;
  margin: 0 auto;
  color: #fff;
  font-weight: 300;
}

.icon-btns .icon-box2 .icon-bg2 {
  position: absolute;
  left: 0;
  top: 0;
}

.form-check-inline {
  line-height: 1.7em;
}

.whatsapp-icon {
  color: #60D41E;
}

.search-box {
  position: relative;
}

.search-box .input-group-append {
  position: absolute;
  right: 10px;
  top: 0;
}

.search-box .input-group-append .input-group-text {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////@at-root
/// 
.timeline {
  padding: 15px 0px 15px 20px;
  border-left: 1px dashed #E7E7EB;
}

.timeline li {
  position: relative;
}


.timeline li span.timeline-icon {
  position: absolute;
  content: "";
  left: -25px;
  top: 3px;
  padding: 5px;
  background: #0066CC;
  border-radius: 50%;
}

.numbers-timeline li span.timeline-icon {
  left: -30px;
  top: -2px;
  padding: 2px;
  text-align: center;
  color: #fff;
  width: 22px;
  height: 22px;
  font-size: 12px;
  line-height: 18px;
}

.timeline li h5 {
  font-size: 1rem;
  margin-bottom: .75rem;
}

.timeline .title-text {
  font-family: 'sf_bold';
  min-width: 130px;
  padding-right: 15px;
}

.numbers-timeline .title-text {
  font-family: 'sf_reg';
}

.history-tab-card .tab-pane {
  padding-left: 10px;
}


/* Inquiry */

.form-check-inline {
  line-height: 1.7em;
}

.whatsapp-icon {
  color: #60D41E;
}

.search-box {
  position: relative;
}

.search-box .input-group-append {
  position: absolute;
  right: 10px;
  top: 0;
}

.search-box .input-group-append .input-group-text {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.fw-bold {
  font-family: 'sf_bold';
  font-weight: normal !important;
}

.grey-card-header {
  border-radius: 1.2rem 1.2rem 0 0;
}

.grey-card-header h5 {
  margin-bottom: 0;
}

.side-mark {
  color: #fff;
  border-radius: 3px;
  text-transform: uppercase;
  padding: 4px 8px;
  font-size: 13px;
}

.left-mark {
  background: #6C5FFC;
}

.right-mark {
  background: #F34F65;
}

.right .material-symbols-outlined {
  opacity: 0.3;
}

.font-20 {
  font-size: 20px !important;
}

.right span {
  margin-left: 8px;
}

.form-check {
  line-height: 1.7;
  margin-bottom: 0.25rem;
}

.form-check-label {
  margin-bottom: 0 !important;
}

.box-50 .form-check {
  width: 50%;
  float: left;
  padding-right: 15px;
}

.optional-text {
  font-size: .75rem;
  opacity: 0.5;
}

.pagination .page-link {
  color: #141414;
  font-size: 0.75rem;
}

.page-link.active,
.active>.page-link {
  color: #fff;
  background: #141414;
  border-color: #141414;
}

.pagination {
  border-radiusL: 2px !important;
}

.page-item:first-child .page-link {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.page-title-right a {
  font-size: 0.75rem;
}

.page-title-right a .material-symbols-outlined {
  font-size: 20px;
}

/*=================================================
Media Quary
=================================================*/
@media (max-width:1599px) {
  .dashboard-boxes ul li {
    width: calc(20% - 20px);
  }

  .history-tab-card .icon-box {
    height: 30px;
    width: 30px;
    padding: 4px;
  }

  .nav-pills .nav-link {
    padding: 0.85rem;
  }

  .history-tab-card .icon-box span {
    font-size: 22px;
  }

  .appointment-detail-card .detail .card-block {
    width: 20%;
  }

  .appointment-detail-card .detail .card-block:after {
    display: none;
  }

}

@media (max-width:1399px) {
  .dashboard-boxes ul li {
    width: calc(25% - 20px);
  }

  .wide-th {
    width: auto;
  }

  .select-service table th,
  .select-service table td {
    padding-left: 10px;
    padding-right: 10px;
  }

  .appointment-detail-card .detail .card-block {
    width: 25%;
  }
}

@media (max-width:1199px) {
  .dashboard-boxes ul li {
    width: calc(33.333% - 20px);
  }

  .appointment-detail-card .detail .card-block {
    width: 33.33%;
  }
}

@media (max-width:992px) {
  .dashboard-boxes ul li {
    width: calc(50% - 20px);
  }

  .navbar-custom .app-search form {
    width: auto;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  .appointment-detail-card .detail .card-block {
    width: 50%;
  }

  .box-50 .form-check {
    width: 100%;
  }

  .wide-100-pad {
    width: 100%;
  }
}

@media (max-width:767px) {
  .dashboard-boxes ul li {
    width: calc(33.333% - 20px);
  }

  .content-page {
    padding: 70px 0px 65px
  }

  .calendar-wraper {
    margin-top: 30px;
  }

  .navbar-custom {
    padding: 0 10px;
  }

  .appointment-detail-card .detail .card-block {
    width: 33.33%;
  }


}

@media (max-width:639px) {
  .dashboard-boxes ul li {
    width: calc(50% - 20px);
  }

  .appointment-detail-card .detail .card-block {
    width: 50%;
  }

}

.form-check-inline {
  line-height: 1.7em;
}

.bgcolor2 {
  background-color: #f4f5f7 !important;
}

// ::ng-deep .mat-form-field
//   {
//     color: #212529;
//     font-size: 14px;
//     font-family: 'sf_med'!important;
//     display: block;
//     width: 100%;
//      padding: 0.375rem 0.001rem;
//     font-weight: 500;
//     line-height: 1.5;
//     background-color: #fff;
//     background-clip: padding-box;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     border-radius: 0.375rem;
//     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//   }
.form-control2 {
  background-color: #ffffff;
  color: #ABABAB;
  font-size: 14px;
  border-radius: 0.275rem;
  font-family: 'sf_reg' !important;
}

mat-label {
  color: #ABABAB;
}

.genderClass {
  font-family: 'sf_reg' !important;
  font-size: 15px;
  color: #141414;
}

.wApp {
  margin-top: 1.5rem;
}

mat-error {
  margin-top: 0.3rem;
}


.blue_col {
  color: #0066CC;
  margin-top: 1rem;
}

h5 {
  font-weight: normal !important;
  font-family: 'sf_bold' !important;
  font-size: 1rem !important;
}

.app-dashboard .card h2 {
  font-size: 1.75rem;
}

:host ::ng-deep .fc .fc-bg-event {
  opacity: 10rem !important;
} 
::ng-deep .mat-calendar-body-cell{
  padding-right: 0px !important;
  padding-left: 0px !important;
}